.App {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6EFE9;
  font-family: "Black Delights Font", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

h1 {
  font-size: 1.7rem;
  letter-spacing: 4px;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.quote {
  z-index: 10;
  padding: 2.2rem;
}

@media screen and (min-width: 769px) {
  .quote {
    max-width: 80%;
  }
}


.background-circle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.App-logo {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 10;
}